import React from 'react';
import { Footer } from '../';

interface CProps {
    children: any;
    hideContact?: boolean
}

export const Content = (props: CProps) =>
    <>
        <div id="content" className="qbl-content">
            {props.children}
            <Footer hideContact={props.hideContact} />
        </div>
    </>;