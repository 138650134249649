import * as React from "react"

interface DetailsProps {
    title?: string;
    content: React.ReactNode[];
    centred?: boolean;
    titleCentred?: boolean;
}

export const Details = (props: DetailsProps) => <>
    {props.title &&
        <>
            <div className="row pt-4 pb-2 qbl-content">
                <div className={"col" + ((((props.centred ?? false) || (props.titleCentred ?? false))) ? " text-center" : "")}>
                    <h2>{props.title}</h2>
                </div>
            </div>
        </>
    }
    <div className={"row qbl-content" + (props.title ? " pt-0 pb-4" : " py-4") }>
        {props.content.map((contentItem, index) =>
            <React.Fragment key={index}>
                {/* <div className="col d-flex align-items-start"> */}
                <div className={"col d-flex align-items-start" + ((props.centred ?? false) ? " text-center" : "")}>
                    <div className="w-100">
                        {contentItem}
                    </div>
                </div>
                <div className="w-100 d-block d-sm-none"></div>
            </React.Fragment>
        )}
    </div >
</>;
