import * as React from "react"

interface PageHeadingProps {
    title: React.ReactNode;
    centred?: boolean;
}

export const PageHeading = (props: PageHeadingProps) => <>
    <div className="row py-4">
        {/* <div className="col"> */}
        <div className={"col" + ((props.centred ?? false) ? " text-center" : "")}>
            <h1>{props.title}</h1>
        </div>
    </div>
</>;
