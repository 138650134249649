import * as React from "react"
import { Link } from "gatsby";

import dashboard from "../../images/image01.png";

type ActionProps = {
    to: string;
    caption: string;
}
type BannerProps = {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    action?: ActionProps;
    sparkle?: React.ReactNode;
}

export function Banner(props: BannerProps) {
    const actionButton = props.action ?
        <div className="d-grid d-sm-inline gap-2">
            <Link className="btn btn-primary px-sm-4" to={props.action.to}>{props.action.caption}</Link>
        </div>
        : <></>;

    return (<>
        <div className="qbl-banner pb-4">
            <div className="row row-cols-1 row-cols-sm-2 gy-4">
                <div className="col py-2 px-4 text-center text-sm-start">
                    <h1>{props.title}</h1>
                    {props.subtitle}
                    {actionButton}
                </div>
                <div className="col px-2 pe-sm-4 pe-md-2 pe-lg-0 px-sm-0">
                    {props.sparkle && props.sparkle}
                </div>
            </div>
        </div>
    </>);
}