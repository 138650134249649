import { Link } from "gatsby";
import React, { useState } from "react";
// import logo from "../../images/qebal-logo.svg";
import qebal from "../../images/qebal.svg";


interface NavItemProps {
    to: string;
    className?: string;
    children: React.ReactNode
}


export const Navigation = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const NavItem = (props: NavItemProps) =>
        <li className={"nav-item py-0 " + (props.className ?? "")}>
            <Link onClick={() => setShowMenu(false)} className="nav-link" to={props.to}>
                {props.children}
            </Link>
        </li>;

    return <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top qbl-navigation">

        <div className="container-lg px-4">
            <Link className="navbar-brand" to="/">
                <img src={qebal} className="my-0" width="120px" height="23x" alt="" aria-label="Home" />
            </Link>
            <button onClick={() => setShowMenu(!showMenu)} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={"collapse navbar-collapse" + (showMenu ? " show" : "")} id="navbarTogglerDemo01">
                <ul className="navbar-nav me-auto mb-4 mb-sm-0 pt-4 pt-md-0 justify-content-end flex-grow-1">
                    <NavItem to="/why-qebal-people-analytics/" className="d-none d-md-block d-lg-none">Why Qebal</NavItem>
                    <NavItem to="/why-qebal-people-analytics/" className="d-md-none d-lg-block">Why Qebal People Analytics</NavItem>

                    {/* <NavItem to="/services/">Services</NavItem> */}
                    
                    <NavItem to="/people-analytics-platform/" className="d-none d-md-block d-lg-none">Platform</NavItem>
                    <NavItem to="/people-analytics-platform/" className="d-md-none d-lg-block">Our Platform</NavItem>
                    
                    <NavItem to="/pricing/">Pricing</NavItem>
                    
                    <NavItem to="/about-us/" className="d-none d-md-block d-lg-none">About</NavItem>
                    <NavItem to="/about-us/" className="d-md-none d-lg-block">About us</NavItem>

                    <li className="nav-item my-0 py-0 d-md-none"><hr className="py-0 my-0 dropdown-divider" /></li>

                    <li className="nav-item py-0 d-md-none">
                        <Link onClick={() => setShowMenu(false)} className="nav-link" to="/people-analytics/">People analytics for <strong>HR Generalists &amp; HRBPs</strong></Link>
                    </li>
                    <li className="nav-item py-0 d-md-none">
                        <Link onClick={() => setShowMenu(false)} className="nav-link" to="/workforce-planning/">Workforce planning for <strong>HR Leaders</strong></Link>
                    </li>
                    <li className="nav-item py-0 d-md-none">
                        <Link onClick={() => setShowMenu(false)} className="nav-link" to="/dashboards-and-reports/">Dashboards &amp; reports for <strong>Senior Execs</strong></Link>
                    </li>
                    <li className="nav-item py-0 d-md-none">
                        <Link onClick={() => setShowMenu(false)} className="nav-link" to="/transformation-analytics/">Transformation analytics for <strong>Change Leads</strong></Link>
                    </li>

                    <li className="nav-item my-0 py-0 d-md-none"><hr className="py-0 my-0 dropdown-divider" /></li>

                    <NavItem to="/contact-us/"><strong>Contact us</strong></NavItem>
                </ul>
            </div>
        </div>
    </nav>;
};
