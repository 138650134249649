import { Link } from "gatsby";
import React from "react"

interface CProps {
    hideContact?: boolean
}

export const Footer = (props: CProps) => <>
    {!props?.hideContact &&
        <div className="qbl-banner mt-2 pt-4">
            <div className="container-lg">

                <div className="row">
                    <div className="col py-2 px-4 text-center text-sm-start">
                        <p>To find out more or to request a demo, please contact us.</p>

                        <div className="d-grid d-sm-inline gap-2 mx-4 mx-sm-0">
                            <Link className="btn btn-secondary px-sm-4" to="/contact-us/">Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    <div className="qbl-footer">
        <div className="container-lg">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 mt-4 pt-4 mb-2 gx-4 qbl-content">
                <div className="col pb-4 px-4">
                    <h2>Solutions</h2>
                    <p><Link to="/people-analytics/">People analytics</Link></p>
                    <p><Link to="/workforce-planning/">Workforce planning</Link></p>
                    <p><Link to="/dashboards-and-reports/">Dashboards &amp; reports</Link></p>
                    <p><Link to="/transformation-analytics/">Transformation analytics</Link></p>
                </div>
                <div className="col pb-4 px-4">
                    <h2>Services</h2>
                    <p>Consultancy</p>
                    <p>Implementation support</p>
                </div>
                <div className="col pb-4 px-4">
                    <h2>Resources</h2>
                    <p><Link to="/why-qebal-people-analytics/">Why Qebal People Analytics</Link></p>
                    <p><Link to="/people-analytics-platform/">Analytics platform and technology</Link></p>
                    <p><Link to="/pricing/">Pricing</Link></p>
                </div>
                <div className="col pb-4 px-4">
                    <h2>More information</h2>
                    <p><Link to="/about-us/">About us</Link></p>
                    <p><Link to="/contact-us/">Contact us</Link></p>
                    <p><Link to="/privacy/">Your privacy</Link></p>
                </div>
            </div>

            <div className="row pb-4 qbl-content">
                <div className="col px-4">
                    <div className="small">
                        <p>
                            The Qebal People Analytics platform is provided by <span className="nowrap">HR-Fundamentals ltd.</span> © <span className="nowrap">HR-Fundamentals ltd.</span>
                        </p>
                        <p>
                            Registered in England and Wales, number 0561&nbsp;5583.&nbsp;
                            <br className="d-sm-none" />
                            20 Market Place, Kingston upon Thames KT1 1JP
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</>;